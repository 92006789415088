import styles from '@/styles/pages/Auth.module.scss';
import StreamonLogo from '@/components/Logo';
import SInput from '@/components/base/sInput';
import SButton from '@/components/base/sButton';
import Link from 'next/link';
import { useState } from 'react';

export default function Auth() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className={styles.authPage}>
      <div className={styles.loginCard}>
        <StreamonLogo size={60} />
        <h1 className={styles.title}>Sign in to your Account</h1>
        <form onSubmit={(e) => e.preventDefault()}>
          <SInput
            id="email"
            label="Email Address"
            placeholder="jhon@email.com"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <SInput
            id="password"
            label="Password"
            placeholder="•••••••••••••••"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <div className={styles.disclaimer}>
            <p>
              By continuing you agree to Streamon's Terms of Service and Privacy
              Policy
            </p>
          </div>
          <SButton
            content="Sign In to My Account"
            theme='aura'
            onClick={() => {
              setIsLoading((isLoading) => !isLoading);
            }}
            isLoading={isLoading}
          />
          <div className={styles.helpText}>
            New to Streamon? <Link href="/">Create your account here</Link>
          </div>
        </form>
      </div>
    </div>
  );
}
