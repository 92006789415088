import styles from "./sInput.module.scss";
import CopyIcon from "../icons/CopyIcon";
import { useRef } from "react";

export default function SInput({
  id,
  label,
  placeholder,
  type = "text",
  value,
  onChange,
  allowCopy = false,
  editable = true,
}: {
  id: string;
  label: string | undefined;
  placeholder?: string | undefined;
  type?: string;
  value: string;
  allowCopy?: boolean | undefined;
  editable?: boolean | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {

  const copyToClipBoard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className={styles.inputContainer}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className={styles.inputWrapper}>
        <input
          type={type}
          value={value}
          onChange={onChange}
          placeholder={
            placeholder || (type === "password" && "•••••••••••••••") || ""
          }
          disabled={!editable}
        />
        {allowCopy && (
          <div
            className={styles.copyIcon}
            onClick={() => copyToClipBoard(value)}
          >
            <CopyIcon size={20} />
          </div>
        )}
      </div>
    </div>
  );
}
